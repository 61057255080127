<template>
  <base-layout-two page-title="Inventory" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16">
      <h1>Inventory</h1>
      <ion-button class="regular-button" expand="block" router-link="/resources/create" v-if="false">
        New Inventory
      </ion-button>
    </section>

    <ion-list>
      <ion-item-group v-for="(group, index) in inventory_groups" :key="index">
        <ion-item-divider class="" sticky>
          <ion-label>{{ index }}</ion-label>
        </ion-item-divider>

        <inventory-list-item v-for="inventory in group" :key="inventory.id" :router-link="`/inventories/${inventory.id}`"
          :inventory="inventory">
        </inventory-list-item>
      </ion-item-group>
    </ion-list>
  </base-layout-two>
</template>

<script>
import { IonList, IonItemGroup, IonItemDivider, IonLabel, IonButton } from "@ionic/vue";
import InventoryListItem from "@/components/inventories/InventoryListItem.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    IonList,
    IonItemGroup,
    IonItemDivider,
    IonLabel,
    IonButton,
    InventoryListItem,
  },

  computed: {
    ...mapGetters(["globalProperty"]),
  },

  data() {
    return {
      inventories: [],
      inventory_groups: {},
    };
  },

  ionViewWillEnter() {
    this.fetchInventories();
  },

  methods: {
    async fetchInventories() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/inventories`
        )
        .then((response) => {
          this.inventories = response.data.inventories;

          this.inventories.sort(function(a,b) {
            return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
          })

          // Sort the Count History
          for (let inventory of this.inventories) {
            inventory.inventory_counts.sort((a, b) => {
              return (
                new Date(b.date) - new Date(a.date) || b.id - a.id
              );
            })
          }

          // Parse into Groups
          this.inventory_groups = this.inventories.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
          }, Object.create(null));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>