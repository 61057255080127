<template>
  <ion-item :lines="lines" class="">
    <ion-label>
      <div class="h6">
        {{ inventory.name }}
      </div>

    </ion-label>

    <ion-label slot="end" class="ion-text-right ion-no-margin"> 
      {{ count }}
      <p>{{ countAsOf }}</p>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel } from "@ionic/vue";
import { formatDate } from "@/util/helpers"

export default {
  props: {
    inventory: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },

  components: {
    IonItem,
    IonLabel,
  },

  computed: {
    count() {
      // Get the latest manual balance if it exists
      if (this.inventory.inventory_counts.length > 0 &&
        this.inventory.inventory_counts[0].type === "balance") {
        return this.inventory.inventory_counts[0].count
      }
      // Add the latest manual balance with the last additions and subtractions
      else {
        let indexOfNextBalance = this.inventory.inventory_counts.findIndex((a) => a.type === "balance")

        let count = this.inventory.inventory_counts.slice(0, indexOfNextBalance + 1).reduce(
          (partial_sum, a) => partial_sum + a.count,
          0
        );
        return count.toLocaleString("en-US")
      }
    },

    countAsOf() {
      if(this.inventory.inventory_counts.length > 0){
        return formatDate(this.inventory.inventory_counts[0].date, "MMM d, yyyy")  
      }
      return ""
    },
  },

  data(){
    return {
    }
  }
};
</script>

<style scoped>
ion-badge {
  font-weight: 300;
}

ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>